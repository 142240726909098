import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {IconButton, Tooltip, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {changePageTitle, setAlert} from "../redux/actions";
import {DateUtil} from "../util/date-util";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomRow from "../custom-row/custom-row";
import {NumberUtil} from "../util/number-util";
import {CaixaEmAberto} from "./caixa-em-aberto";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import {CashRegisterService} from "../cash-register/cash-register.service";
import {Search} from "@mui/icons-material";
import {RelatorioService} from "../relatorio/relatorio.service";
import {FileUtil} from "../util/file-util";
import {decodeUint8Array} from "../util/string-util";

const CheckoutClose = React.memo(() => {
    const [caixaAberto, setCaixaAberto] = useState<CaixaEmAberto>();
    const [valorEmCaixa, setValorEmCaixa] = useState<number>(0);
    const [sangria, setSangria] = useState<number>(0);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const getCaixaAberto = useCallback(async () => {
        CashRegisterService.getCaixaAberto().then((result) => {
            setCaixaAberto(result);
            setValorEmCaixa(result.valorEmCaixa);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
            navigate('/home');
        })
    }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(changePageTitle("Fechar caixa"));
        getCaixaAberto().then();
    }, [dispatch, getCaixaAberto]);

    const close = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        CashRegisterService.fechaCaixa(valorEmCaixa, sangria).then(() => {
            dispatch(setAlert({show: true, message: 'Caixa fechado com sucesso', severity: 'success'}));
            navigate('/home');
        }).catch((error) => {
            dispatch(setAlert({
                show: true,
                message: 'Erro ao fechar caixa - ' + error.data.message,
                severity: 'error'
            }));
        })
    }

    const cancel = () => {
        navigate('/home');
    }

    const details = () => {
        if (caixaAberto) {
            RelatorioService.getCashJournal(caixaAberto.data, caixaAberto.data).then(function (result) {
                FileUtil.openPdfInNewTab(result);
            }, (result) => {
                if (result.status === 404) {
                    dispatch(setAlert({show: true, message: 'Não há lançamentos para o período informado', severity: 'error'}));
                    return;
                }
                const error = decodeUint8Array(result);
                dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
            })
        }
    }

    if (caixaAberto) {
        return (
            <form onSubmit={close}>
                <CustomRow>
                    <Typography variant="subtitle1" gutterBottom>
                        Caixa aberto: {DateUtil.javaSqlDateToDateToLocaleDateString(caixaAberto.data)}
                        <Tooltip title="Detalhes">
                            <IconButton type={"button"} onClick={details}>
                                <Search/>
                            </IconButton>
                        </Tooltip>
                    </Typography>
                </CustomRow>
                <CustomRow>
                    <CustomTextField fullWidth label="Valor total em caixa (R$)" type="number"
                                     value={caixaAberto.valorEmCaixa} disabled/>
                    <CustomTextField fullWidth required autoFocus label="Informe o valor em caixa (R$)" type="number"
                                     value={valorEmCaixa || ''}
                                     onChange={(event) => setValorEmCaixa(parseFloat(event.target.value))}/>
                </CustomRow>
                <Typography variant="subtitle1" gutterBottom>
                    Diferença: {NumberUtil.currencyFormat(valorEmCaixa - caixaAberto.valorEmCaixa)}
                </Typography>
                <CustomRow>
                    <CustomTextField fullWidth label="Informe o valor da sangria do caixa (R$)" type="number"
                                     value={sangria || ''}
                                     onChange={(event) => setSangria(parseFloat(event.target.value))}/>
                </CustomRow>
                <CustomButtonGroup>
                    <CustomButton type="submit" color="primary">Fechar</CustomButton>
                    <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
                </CustomButtonGroup>
            </form>
        )
    }
    return null;

})

export default React.memo(CheckoutClose);
