import {DateUtil} from "../typescript/util/date-util";
import {AppService} from "../typescript/app/app.service";
import {PageResponse} from "../typescript/util/page-response";
import {Product} from "./product";
import {Category} from "../category/category";
import {CategoryService} from "../category/category.service";
import {ColorService} from "../color/color.service";
import {ProductValue} from "./product-value";
import {PersonService} from "../person/person.service";

const METAL = 'METAL';
const ACETATO = 'ACETATO';
const PLAQUETA = 'PLAQUETA';
const PONTE_DE_METAL = 'PONTE_DE_METAL';

class ProductService {

    static API_URL = "/api/produtos/v3";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Product>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : '',
            categoria: sort?.startsWith('category') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return this.errorCallBack();
        })
    };

    static findAllSimple = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<Product>> => {
        return AppService.getAuthorized(this.API_URL + "/simple", {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : '',
            categoria: sort?.startsWith('category') ? filter : ''
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return this.errorCallBack();
        })
    };

    static findAllToSale = (page?: number, size?: number, sort?: string, filter?: string, ativo?: boolean, idCategoria?: number): Promise<PageResponse<Product>> => {
        return AppService.getAuthorized(this.API_URL + "/sale", {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : '',
            descricao: sort?.startsWith('descricao') ? filter : '',
            categoria: sort?.startsWith('categoria') ? filter : '',
            ativo: ativo,
            idCategoria: idCategoria
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return this.errorCallBack();
        })
    };

    static findOne(id: number, ativo?: boolean) {
        return AppService.getAuthorized(this.API_URL + "/" + id, {ativo: ativo}).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static save(item: Product) {
        let itemToSave = item;
        if (!itemToSave.estoqueMinimo) {
            itemToSave.estoqueMinimo = 0;
        }
        if (!itemToSave.estoqueIdeal) {
            itemToSave.estoqueIdeal = 0;
        }
        if (!itemToSave.estoqueAtual) {
            itemToSave.estoqueAtual = 0;
        }
        if (itemToSave.valores) {
            for (let valor of itemToSave.valores) {
                if (!valor.custo) {
                    valor.custo = 0;
                }
            }
        }
        if (itemToSave.color && !itemToSave.color.id) {
            itemToSave.color = null;
        }
        if (itemToSave.material1Color && !itemToSave.material1Color.id) {
            itemToSave.material1Color = null;
        }
        if (itemToSave.material2Color && !itemToSave.material2Color.id) {
            itemToSave.material2Color = null;
        }
        if (itemToSave.material3Color && !itemToSave.material3Color.id) {
            itemToSave.material3Color = null;
        }
        if (itemToSave.id) {
            return AppService.postAuthorized(this.API_URL + "/" + itemToSave.id, itemToSave);
        } else {
            let params = { stock: 0 };
            if (itemToSave.estoqueAtual > 0) {
                params.stock = itemToSave.estoqueAtual;
            }
            return AppService.postAuthorized(this.API_URL, itemToSave, params);
        }
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static updateValues(percentual: number, startDate: string, endDate: string, category: Category) {
        const params = {
            startDate: startDate,
            endDate: endDate,
            category: category.id,
            percentual: percentual
        }
        return AppService.getAuthorized(this.API_URL + "/updatevalues", params);
    }

    static createProduct() {
        const product: Product = {
            descricao: '',
            categoria: CategoryService.createCategory(),
            ativo: false,
            controlaEstoque: false,
            unidade: 'UNIDAD',
            ncm: '',
            observacao: '',
            estoqueMinimo: null,
            estoqueAtual: null,
            estoqueIdeal: null,
            valores: [],
            clipon: false,
            color: ColorService.createColor(),
            biggestDiagonal: null,
            vertical: null,
            bridge: null,
            size: null,
            material1: '',
            material1Color: ColorService.createColor(),
            material2: '',
            material2Color: ColorService.createColor(),
            material3: '',
            material3Color: ColorService.createColor(),
            prazoEntrega: '',
            requiresCustomMeasurements: false,
            degreeWithZero: false
        }
        return product;
    }

    static createValor() {
        const productValue: ProductValue = {
            custo: '',
            valor: '',
            dataInicial: DateUtil.toJavaSqlDate(new Date()),
            dataFinal: DateUtil.toJavaSqlDate(DateUtil.addYear(new Date(), 1)),
            fornecedor: PersonService.createPerson(false, false)
        }
        return productValue;
    }

    static stockMovement(productId: number, quantity: number) {
        return AppService.getAuthorized(this.API_URL + "/" + productId + "/stockMovement", {quantidade: quantity});
    }

    static errorCallBack() {
        return {
            content: [],
            totalElements: 0
        };
    }

    static getMaterialOptions() {
        let materialOptions = [];
        materialOptions.push({name: '', description: 'Nenhum'});
        materialOptions.push({name: 0, description: 'Metal'});
        materialOptions.push({name: 1, description: 'Acetato'});
        materialOptions.push({name: 2, description: 'Acetato com plaquetas'});
        materialOptions.push({name: 3, description: 'Acetato com ponte de metal'});
        materialOptions.push({name: 4, description: 'Metal com acetato'});
        materialOptions.push({name: 5, description: 'Acetato com metal'});
        materialOptions.push({name: 6, description: 'Acetato com metal e plaquetas'});
        return materialOptions;
    }

    static setMaterials(item: Product, material: number) {
        switch (material) {
            case 0:
                item.material1 = METAL;
                item.material2 = null;
                item.material3 = null;
                break;
            case 1:
                item.material1 = ACETATO;
                item.material2 = null;
                item.material3 = null;
                break;
            case 2:
                item.material1 = ACETATO;
                item.material2 = PLAQUETA;
                item.material3 = null;
                break;
            case 3:
                item.material1 = ACETATO;
                item.material2 = PONTE_DE_METAL;
                item.material3 = null;
                break;
            case 4:
                item.material1 = METAL;
                item.material2 = ACETATO;
                item.material3 = null;
                break;
            case 5:
                item.material1 = ACETATO;
                item.material2 = METAL;
                item.material3 = null;
                break;
            case 6:
                item.material1 = ACETATO;
                item.material2 = METAL;
                item.material3 = PLAQUETA;
                break;
            default:
                item.material1 = null;
                item.material2 = null;
                item.material3 = null;
                break;
        }
    }

    static getMaterials(item: Product) {
        if (item.material1 === null && item.material2 === null && item.material3 === null) {
            return null;
        }
        if (item.material1 === METAL && item.material2 === null && item.material3 === null) {
            return 0;
        }
        if (item.material1 === ACETATO && item.material2 === null && item.material3 === null) {
            return 1;
        }
        if (item.material1 === ACETATO && item.material2 === PLAQUETA && item.material3 === null) {
            return 2;
        }
        if (item.material1 === ACETATO && item.material2 === PONTE_DE_METAL && item.material3 === null) {
            return 3;
        }
        if (item.material1 === METAL && item.material2 === ACETATO && item.material3 === null) {
            return 4;
        }
        if (item.material1 === ACETATO && item.material2 === METAL && item.material3 === null) {
            return 5;
        }
        if (item.material1 === ACETATO && item.material2 === METAL && item.material3 === PLAQUETA) {
            return 6;
        }
    }
}

export {ProductService};
