import {Drawer, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Menu from "./menu";
import {Home} from "@mui/icons-material";
import {setAlert} from "../typescript/redux/actions";
import * as StringUtil from "../typescript/util/string-util";
import {FileUtil} from "../typescript/util/file-util";
import {StockService} from "../stock/stock.service";
import {NfeService} from "../nfe/nfe.service";
import {OpenMenu} from "./open-menu";

interface AppMenuListProps {
    showDrawer: boolean;
    setShowDrawer: (show: boolean) => void;
}

const AppMenuList = ({showDrawer, setShowDrawer}: AppMenuListProps) => {

    const dispatch = useDispatch();

    const [openMenu, setOpenMenu] = useState<OpenMenu>({
        openCadastro: false,
        openFinanceiro: false,
        openVendas: false,
        openDadosAcessorios: false,
        openEstoque: false,
        openConsulta: false,
        openAdministracao: false,
        openCaixa: false,
        openCampaign: false
    });

    const handleClickCadastro = () => {
        const open = !openMenu.openCadastro;
        setOpenMenu({
            openCadastro: open,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickVendas = () => {
        const open = !openMenu.openVendas;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: open,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickFinanceiro = () => {
        const open = !openMenu.openFinanceiro;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: open,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickDadosAcessorios = () => {
        const open = !openMenu.openDadosAcessorios;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: open,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickEstoque = () => {
        const open = !openMenu.openEstoque;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: open,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickConsulta = () => {
        const open = !openMenu.openConsulta;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: open,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickAdministracao = () => {
        const open = !openMenu.openAdministracao;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: open,
            openCaixa: false,
            openCampaign: false
        })
    };

    const handleClickCaixa = () => {
        const open = !openMenu.openCaixa;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: open,
            openCampaign: false
        })
    };

    const handleClickCampaign = () => {
        const open = !openMenu.openCampaign;
        setOpenMenu({
            openCadastro: false,
            openFinanceiro: false,
            openVendas: false,
            openDadosAcessorios: false,
            openEstoque: false,
            openConsulta: false,
            openAdministracao: false,
            openCaixa: false,
            openCampaign: open
        })
    };

    const resetStock = () => {
        StockService.resetStock().then(() => {
            dispatch(setAlert({show: true, message: 'Estoque zerado com sucesso', severity: 'success'}));
        }, () => {
            dispatch(setAlert({show: true, message: 'Erro ao zerar estoque', severity: 'error'}));
        })
    }

    const getInventory = () => {
        StockService.getInventory().then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            const error = StringUtil.decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const atualizaCadeiaCertificadora = () => {
        NfeService.atualizaCadeiaCertificadora().then(() => {
            dispatch(setAlert({show: true, message: 'Atualização realizada com sucesso', severity: 'success'}));
        }, () => {
            dispatch(setAlert({show: true, message: 'Erro ao atualizar cadeia certificadora', severity: 'error'}));
        })
    }

    const subMenusCaixa = [
        {title: 'Abrir', href: '/checkout/open'},
        {title: 'Fechar', href: '/checkout/close'}
    ]

    const subMenusCadastro = [
        {title: 'Atividades', href: '/activities'},
        {title: 'Bairros', href: '/neighborhoods'},
        {title: 'Categorias', href: '/categories'},
        {title: 'Cidades', href: '/cities'},
        {title: 'Consultas', href: '/consultas'},
        {title: 'Cores', href: '/colors'},
        {title: 'Estados', href: '/states'},
        {title: 'Feriados', href: '/holidays'},
        {title: 'Grupos de categoria', href: '/categorygroups'},
        {title: 'Instagram', href: '/instagrams'},
        {title: 'Laboratórios', href: '/laboratories'},
        {title: 'Pessoas', href: '/persons'},
        {title: 'Produtos', href: '/products'},
        {title: 'Reservas', href: '/reserves'}
    ]

    const subMenusVendas = [
        {title: 'Análise de metas', href: '/goal/analysis'},
        {title: 'Análise de vendas', href: '/sales/analysis'},
        {title: 'Comissões mensais', href: '/comissions'},
        {title: 'Comissões sobre produtos', href: '/comissionproducts'},
        {title: 'Metas', href: '/usergoals'},
        {title: 'Perfil das vendas', href: '/sales/profile'},
        {title: 'Pós vendas', href: '/sales/after'},
        {title: 'Última compra', href: '/sales/last'},
        {title: 'Vendas', href: '/sales'},
        {title: 'Visão geral', href: '/sales/overview'}
    ]

    const subMenusEstoque = [
        {title: 'Inventário', onClick: getInventory},
        {title: 'Movimentar', href: '/products/stockmovement'},
        {title: 'Zerar estoque', onClick: resetStock}
    ]

    const subMenusConsulta = [
        {title: 'Aniversariantes', href: '/aniversariantes'},
        {title: 'Curva ABC', href: '/curvaABC'},
        {title: 'Diário de caixa', href: '/cashJournal'},
        {title: 'Relatórios de ponto', href: '/pontoreport'},
        {title: 'Relatórios para DRE', href: '/drereports'},
        {title: 'Vendas', href: '/consultsales'}
    ]

    const subMenusFinanceiro = [
        {title: 'Análise de despesas', href: '/expenseanalysis'},
        {title: 'Análise de orçamentos', href: '/monthlybudgetanalysis'},
        {title: 'Bandeiras', href: '/brands'},
        {title: 'Documentos', href: '/documents'},
        {title: 'Contas', href: '/wallets'},
        {title: 'Fluxo de caixa', href: '/cashflow'},
        {title: 'Fluxo de pagamentos', href: '/paymentflux'},
        {title: 'Modelos fiscais', href: '/fiscalmodels'},
        {title: 'Movimentar', href: '/wallets/movement'},
        {title: 'Notas emitidas', href: '/nfe/consulta'},
        {title: 'Orçamento mensal', href: '/monthlybudget'},
        {title: 'Planos de contas', href: '/planodecontas'},
        {title: 'Registros de caixa', href: '/cashregisters'},
        {title: 'Tipos de gastos', href: '/centrodecustos'},
        {title: 'Tipos de pagamento', href: '/paymenttypes'},
        {title: 'Visão geral', href: '/financial/overview'}
    ]

    const subMenusDadosAcessorios = [
        {title: 'Como conheceu a loja', href: '/conheceulojas'},
        {title: 'Estilos', href: '/estilos'},
        {title: 'Quem decide a compra', href: '/decidecompras'},
        {title: 'Quem efetua a compra', href: '/efetuacompras'},
        {title: 'Quem influencia a compra', href: '/influenciacompras'},
        {title: 'Preferências', href: '/preferencias'},
        {title: 'Profissões', href: '/profissaos'},
        {title: 'Temperamentos', href: '/temperamentos'}
    ]

    const subMenusAdministracao = [
        {title: 'Atualizar cadeia certificadora', onClick: atualizaCadeiaCertificadora},
        {title: 'Manutenção de ponto', href: '/ponto/management'},
        {title: 'Parâmetros', href: '/parameters'},
        {title: 'Usuários', href: '/users'}
    ]

    const subMenusCampaign = [
        {title: 'Tipos de campanha', href: '/campaigntypes'},
        {title: 'Tipos de contato da campanha', href: '/campaigncontacttypes'},
        {title: 'Campanhas', href: '/campaigns'}
    ]

    return (
        <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
            <List>
                <ListItemButton component="a" href="/">
                    <ListItemIcon><Home/></ListItemIcon>
                    <ListItemText primary="Home"/>
                </ListItemButton>
                <Menu title={"Caixa"} handleClick={handleClickCaixa} open={openMenu.openCaixa}
                      submenus={subMenusCaixa}/>
                <Menu title={"Cadastro"} handleClick={handleClickCadastro} open={openMenu.openCadastro}
                      submenus={subMenusCadastro}/>
                <Menu title={"Vendas"} handleClick={handleClickVendas} open={openMenu.openVendas}
                      submenus={subMenusVendas}/>
                <Menu title={"Financeiro"} handleClick={handleClickFinanceiro} open={openMenu.openFinanceiro}
                      submenus={subMenusFinanceiro}/>
                <Menu title={"Dados acessórios"} handleClick={handleClickDadosAcessorios}
                      open={openMenu.openDadosAcessorios} submenus={subMenusDadosAcessorios}/>
                <Menu title={"Estoque"} handleClick={handleClickEstoque} open={openMenu.openEstoque}
                      submenus={subMenusEstoque}/>
                <Menu title={"Consultas"} handleClick={handleClickConsulta} open={openMenu.openConsulta}
                      submenus={subMenusConsulta}/>
                <Menu title={"Campanhas"} handleClick={handleClickCampaign} open={openMenu.openCampaign}
                      submenus={subMenusCampaign}/>
                <Menu title={"Administração"} handleClick={handleClickAdministracao} open={openMenu.openAdministracao}
                      submenus={subMenusAdministracao}/>
            </List>
        </Drawer>
    )
}

export default React.memo(AppMenuList);
