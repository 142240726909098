import React, {useEffect, useState} from 'react';
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import {SaleItem} from "../../../sale/sale-item";
import {SaleService} from "../../../sale/sale.service";
import {AuthService} from "../../auth/auth.service";
import {CategoryService} from "../../../category/category.service";
import {Category} from "../../../category/category";
import CustomButton from "../../custom-button/custom-button";
import {ProductService} from "../../../product/product.service";
import {setAlert} from "../../redux/actions";
import SearchDialog from "../../search-dialog/search-dialog";
import LensDetail from "./lens-detail";
import CustomButtonGroup from "../../custom-button-group/custom-button-group";
import CustomPanel from "../../custom-panel/custom-panel";
import ForeignKey from "../../foreign-key/foreign-key";
import CustomTextField from "../../custom-text-field/custom-text-field";
import {Product} from "../../../product/product";

interface ItemToInsertProps {
    insertItem: (saleItem: SaleItem | null) => void;
    saleItem?: SaleItem;
    saleItemClosed?: boolean;
    warrantyOperation: boolean;
}

interface CategoryDialogProps {
    show: boolean;
    category: Category | null;
}

const ItemToInsert = ({insertItem, saleItem, saleItemClosed, warrantyOperation}: ItemToInsertProps) => {

    const dispatch = useDispatch();
    const [servicoParaInserir, setServicoParaInserir] = useState<SaleItem>(SaleService.createItemParaInserir(saleItem));
    const [categories, setCategories] = useState<Category[]>([]);
    const [lente, setLente] = useState<boolean>(saleItem ? SaleService.isLens(saleItem) : false);
    const isAdmin = AuthService.userInRole("ADMIN");

    const [categoryDialog, setCategoryDialog] = useState<CategoryDialogProps>({
        show: false,
        category: null
    });

    useEffect(() => {
        const findCategories = async () => {
            const categoriesFound = await CategoryService.getSaleCategories();
            if (categoriesFound) {
                setCategories(categoriesFound);
            }
        }
        findCategories().then();
    }, []);

    const onSelectServicoParaInserir = (servico: Product) => {
        if (servico) {
            let valorUnitarioNovo = servico.valorAtual ? servico.valorAtual : 0;
            let valorUnitarioMinimo = 0;
            if (!isAdmin && valorUnitarioNovo) {
                valorUnitarioMinimo = valorUnitarioNovo;
            }
            const odEsferico = servico.degreeWithZero ? '0' : '';
            const odCilindrico = servico.degreeWithZero ? '0' : '';
            const odEixo = servico.degreeWithZero ? '0' : '';
            const odAdicao = servico.degreeWithZero ? '0' : '';
            const odDnp = servico.degreeWithZero ? '0' : '';
            const odAltura = servico.degreeWithZero ? '0' : '';
            const oeEsferico = servico.degreeWithZero ? '0' : '';
            const oeCilindrico = servico.degreeWithZero ? '0' : '';
            const oeEixo = servico.degreeWithZero ? '0' : '';
            const oeAdicao = servico.degreeWithZero ? '0' : '';
            const oeDnp = servico.degreeWithZero ? '0' : '';
            const oeAltura = servico.degreeWithZero ? '0' : '';
            setServicoParaInserir({
                ...servicoParaInserir,
                servico: servico,
                servicoInvalido: false,
                valorUnitario: valorUnitarioNovo,
                valorUnitarioMinimo: valorUnitarioMinimo,
                odEsferico: odEsferico,
                odCilindrico: odCilindrico,
                odEixo: odEixo,
                odAdicao: odAdicao,
                odDnp: odDnp,
                odAltura: odAltura,
                oeEsferico: oeEsferico,
                oeCilindrico: oeCilindrico,
                oeEixo: oeEixo,
                oeAdicao: oeAdicao,
                oeDnp: oeDnp,
                oeAltura: oeAltura,
                pantoscopicAngle: '',
                curvatureAngle: '',
                croRightEye: '',
                croLeftEye: '',
                readingDistance: '',
                dominantEye: '',
                coefficientHeadEye: '',
            });
            setLente(!!servico.categoria?.lente);
        }
    }

    const changeValorUnitario = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valorUnitario = Number(event.target.value);
        setServicoParaInserir({
            ...servicoParaInserir,
            valorUnitario: valorUnitario
        });
    }

    const onChangeServicoParaInserir = async (id: number) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            servico: {
                id: id
            },
            servicoInvalido: true
        });
        if (id) {
            const servico = await ProductService.findOne(id, true);
            onSelectServicoParaInserir(servico);
        }
    }

    const setQuantidade = (event: React.ChangeEvent<HTMLInputElement>) => {
        const quantidade = Number(event.target.value);
        setServicoParaInserir({
            ...servicoParaInserir,
            quantidade: quantidade
        })
    }

    const setObservacao = (event: React.ChangeEvent<HTMLInputElement>) => {
        const observacao = event.target.value;
        setServicoParaInserir({
            ...servicoParaInserir,
            observacao: observacao
        })
    }

    const setDepende = (event: React.ChangeEvent<HTMLInputElement>) => {
        const depende = event.target.value;
        setServicoParaInserir({
            ...servicoParaInserir,
            depende: depende
        })
    }

    const setSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            size: event.target.value
        })
    }

    const setBiggestDiagonal = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            biggestDiagonal: event.target.value
        })
    }

    const setVertical = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            vertical: event.target.value
        })
    }

    const setBridge = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            bridge: event.target.value
        })
    }

    const setLensBaseCurve = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            lensBaseCurve: event.target.value
        })
    }

    const setDiameter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServicoParaInserir({
            ...servicoParaInserir,
            diameter: event.target.value
        })
    }

    const insere = () => {
        if (servicoParaInserir.servicoInvalido) {
            dispatch(setAlert({show: true, message: 'Produto inválido', severity: 'error'}));
            return;
        }
        if (!servicoParaInserir.quantidade || servicoParaInserir.quantidade === 0) {
            dispatch(setAlert({show: true, message: 'Quantidade inválida', severity: 'error'}));
            return;
        }
        if (!servicoParaInserir.id && (!servicoParaInserir.valorUnitario || servicoParaInserir.valorUnitario === 0)) {
            dispatch(setAlert({show: true, message: 'Valor unitário inválido', severity: 'error'}));
            return;
        }
        if (!warrantyOperation && !isAdmin && (servicoParaInserir.valorUnitario || 0) < (servicoParaInserir.valorUnitarioMinimo || 0)) {
            dispatch(setAlert({show: true, message: 'Valor unitário abaixo do valor de venda', severity: 'error'}));
            return;
        }

        const itemNovo = SaleService.createSaleItemFromServicoParaInserir(servicoParaInserir);
        insertItem(itemNovo);
        setServicoParaInserir(SaleService.createItemParaInserir());
        setLente(false);
    }

    const findProdutos = (page?: number, size?: number, sort?: string, filter?: string) => {
        return ProductService.findAllToSale(page, size, sort, filter, true);
    }

    const renderCategories = () => {
        return !saleItemClosed && categories.map((category, index) => (
            <Grid key={index} item md={3} xs={4}>
                <CustomButton fullWidth type={"button"} variant="outlined"
                              onClick={() => openCategoryDialog(category)}>{category.descricao}</CustomButton>
            </Grid>
        ))
    }

    const openCategoryDialog = (category: Category) => {
        setCategoryDialog({
            show: true,
            category: category
        });
    }

    const closeSearchDialog = () => {
        setCategoryDialog({
            show: false,
            category: null
        });
    }

    const findProdutosPorCategoria = (page?: number, size?: number, sort?: string, filter?: string) => {
        return ProductService.findAllToSale(page, size, sort, filter, true, categoryDialog.category?.id);
    }

    const selectDataFromSearchDialog = (item: Product) => {
        closeSearchDialog();
        onSelectServicoParaInserir(item);
    }

    const onChangeIsLente = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLente(event.target.checked);
        if (!event.target.checked) {
            setServicoParaInserir({
                ...servicoParaInserir,
                odEsferico: '',
                odCilindrico: '',
                odEixo: '',
                odAdicao: '',
                odDnp: '',
                odAltura: '',
                oeEsferico: '',
                oeCilindrico: '',
                oeEixo: '',
                oeAdicao: '',
                oeDnp: '',
                oeAltura: '',
                pantoscopicAngle: '',
                curvatureAngle: '',
                croRightEye: '',
                croLeftEye: '',
                readingDistance: '',
                dominantEye: '',
                coefficientHeadEye: '',
            });
        }
    }

    const renderSearchDialog = () => {
        return categoryDialog.show && (
            <SearchDialog label={"Produtos da categoria " + categoryDialog.category?.descricao}
                          open={categoryDialog.show} onClose={closeSearchDialog} findAll={findProdutosPorCategoria}
                          dataColumnNames={['ID', 'Descrição', 'Valor atual']}
                          dataColumns={['id', 'descricao', 'valorAtual']} dataTypes={['number', 'text', 'currency']}
                          onSelectData={selectDataFromSearchDialog} initialSort={'descricao,asc'}/>
        )
    }

    const renderDetalhesLente = () => {
        return lente && (
            <LensDetail servicoParaInserir={servicoParaInserir} setServicoParaInserir={setServicoParaInserir}/>
        )
    }

    const cancel = () => {
        insertItem(null);
    }

    const renderButtons = () => {
        if (saleItem) {
            return (
                <CustomButtonGroup>
                    <CustomButton type={"button"} color="primary" onClick={insere}>Salvar</CustomButton>
                    <CustomButton type={"button"} color="neutral" onClick={cancel}>Cancelar</CustomButton>
                </CustomButtonGroup>
            )
        }
        return (
            <CustomButtonGroup>
                <CustomButton type={"button"} variant="outlined" onClick={insere}>Inserir</CustomButton>
            </CustomButtonGroup>
        )
    }

    function renderCustomMeasurements() {
        return servicoParaInserir.servico?.requiresCustomMeasurements && (
            <Grid container spacing={1}>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Horizontal" value={servicoParaInserir.size || ''}
                                     onChange={setSize} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Maior diagonal (mm)"
                                     value={servicoParaInserir.biggestDiagonal || ''}
                                     onChange={setBiggestDiagonal} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Vertical (mm)" value={servicoParaInserir.vertical || ''}
                                     onChange={setVertical} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Ponte (mm)" value={servicoParaInserir.bridge || ''}
                                     onChange={setBridge} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Curva base da lente"
                                     value={servicoParaInserir.lensBaseCurve || ''}
                                     onChange={setLensBaseCurve} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField type="number" fullWidth label="Diâmetro" value={servicoParaInserir.diameter || ''}
                                     onChange={setDiameter} InputProps={{inputProps: {min: 0, step: "any"}}}/>
                </Grid>
            </Grid>
        );
    }

    return (
        <CustomPanel title={saleItem ? "Item da venda" : "Inserir produto"}>
            {renderSearchDialog()}
            <Grid container spacing={1} sx={{marginBottom: '8px'}}>
                {renderCategories()}
            </Grid>
            <Grid container spacing={1}>
                <Grid item md={5} xs={12}>
                    <ForeignKey fullWidth label="Produto" value={servicoParaInserir.servico} fieldKey={'id'}
                                initialSort={'descricao,asc'} fieldDescription={'descricao'}
                                labelDescription='Descrição' findAll={findProdutos} disabled={saleItemClosed}
                                onChange={onChangeServicoParaInserir} onSelect={onSelectServicoParaInserir}
                                dataColumnNames={['ID', 'Descrição', 'Categoria', 'Valor atual']}
                                dataColumns={['id', 'descricao', 'categoria.descricao', 'valorAtual']}
                                dataTypes={['number', 'text', 'text', 'currency']}/>
                </Grid>
                <Grid item md={1} xs={12}>
                    <CustomTextField type="number" fullWidth label="Quantidade" value={servicoParaInserir.quantidade}
                                     onChange={setQuantidade} InputProps={{inputProps: {min: 1}}}
                                     disabled={saleItemClosed}/>
                </Grid>
                <Grid item md={2} xs={12}>
                    <CustomTextField fullWidth label="Valor unitário (R$)" type="number" disabled={saleItemClosed}
                                     value={servicoParaInserir.valorUnitario} onChange={changeValorUnitario}
                                     InputProps={{
                                         inputProps: {
                                             min: servicoParaInserir.valorUnitarioMinimo,
                                             step: "any"
                                         }
                                     }}/>
                </Grid>
                <Grid item md={3} xs={12}>
                    <CustomTextField fullWidth label="Observação" value={servicoParaInserir.observacao || ''}
                                     onChange={setObservacao} inputProps={{maxLength: 50}}/>
                </Grid>
                <Grid item md={1} xs={12}>
                    <CustomTextField fullWidth label="Dependência" value={servicoParaInserir.depende || ''}
                                     onChange={setDepende}/>
                </Grid>
            </Grid>
            {renderCustomMeasurements()}
            <div>
                <FormControlLabel control={<Checkbox checked={lente} onChange={onChangeIsLente}/>} label="Lente"/>
                {renderDetalhesLente()}
            </div>
            {renderButtons()}
        </CustomPanel>
    )

}

export default React.memo(ItemToInsert);
