import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {changePageTitle} from "../typescript/redux/actions";
import {Datatable} from "../index";
import {useNavigate} from "react-router-dom";
import {CashRegisterService} from "../typescript/cash-register/cash-register.service";

const CashRegisterList = () => {

    let navigate = useNavigate();
    const [pageParams, setPageParams] = useState(null);
    const dispatch = useDispatch();

    const findAll = useCallback(async (number, size, sort, filter) => {
        const items = await CashRegisterService.findAll(number, size, sort, filter);
        setPageParams({
            number: number,
            size: size,
            sort: sort,
            filter: filter,
            items: items
        });
    }, []);

    useEffect(() => {
        dispatch(changePageTitle("Registros de caixa"));
        findAll(0, parseInt(process.env.REACT_APP_PAGE_SIZE), "id,desc", '').then();
    }, [dispatch, findAll]);

    const editItem = (item) => {
        navigate('/cashregister/' + item.id);
    }

    return pageParams && (
        <Datatable data={pageParams.items.content} totalData={pageParams.items.totalElements}
                   dataColumnNames={['ID', 'Data abertura', 'Hora abertura', 'Data fechamento', 'Hora fechamento', 'Quebra', 'Sangria']}
                   dataColumns={['id', 'dataAbertura', 'horaAbertura', 'dataFechamento', 'horaFechamento', 'quebra', 'sangria']}
                   dataTypes={['number', 'date', 'string', 'date', 'string', 'currency', 'currency']}
                   number={pageParams.number} size={pageParams.size} sort={pageParams.sort} filter={pageParams.filter}
                   sortColumns={['ID', null, null, null, null, null, null]}
                   findAll={findAll} editData={editItem}/>
    );
}

export default React.memo(CashRegisterList);