import React, {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Datatable} from "../index";
import {Typography} from "@mui/material";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import {changePageTitle} from "../typescript/redux/actions";
import {DateUtil} from "../typescript/util/date-util";
import {CashRegisterService} from "../typescript/cash-register/cash-register.service";

const CashRegisterDetail = React.memo(({id, onClose, dialog}) => {

    const [item, setItem] = useState(null);
    const dispatch = useDispatch();

    const findOne = useCallback(async () => {
        const result = await CashRegisterService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle("Registro caixa " + id));
        }
        findOne().then();
    }, [dispatch, findOne, id, dialog]);

    const cancel = () => {
        onClose();
    }

    return item && (
        <React.Fragment>
            <Typography variant="subtitle1" gutterBottom>
                Caixa aberto
                em: {DateUtil.javaSqlDateToDateToLocaleDateString(item.dataAbertura) + " " + item.horaAbertura}
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
                Abertura
            </Typography>

            <Datatable data={item.valoresAbertura} totalData={item.valoresAbertura.length}
                       dataColumnNames={['Carteira', 'Saldo']}
                       dataColumns={['carteira.descricao', 'saldoGeral']} dataTypes={['text', 'currency']}/>

            <Typography variant="subtitle2" gutterBottom>
                Fechamento
            </Typography>

            <Datatable data={item.valoresFechamento} totalData={item.valoresFechamento.length}
                       dataColumnNames={['Carteira', 'Saldo']}
                       dataColumns={['carteira.descricao', 'saldoGeral']} dataTypes={['text', 'currency']}/>
            <CustomButtonGroup>
                <CustomButton type="button" onClick={cancel}>Voltar</CustomButton>
            </CustomButtonGroup>
        </React.Fragment>
    )
});

export default CashRegisterDetail;