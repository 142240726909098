import {DateUtil} from "../typescript/util/date-util";
import {AppService} from "../typescript/app/app.service";
import {Activity} from "./activity";
import {CreateActivityRequest} from "./create-activity-request";

class ActivityService {

    static API_URL = "/api/atividades/v2";

    static findAll = (startDate: string, endDate: string, user: number): Promise<Activity[]> => {
        return AppService.getAuthorized(this.API_URL, {
            startDate: startDate,
            endDate: endDate,
            user: user,
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    };

    static findAllOfCurrentUser = (startDate: string, endDate: string): Promise<Activity[]> => {
        return AppService.getAuthorized(this.API_URL + '/currentUser', {
            startDate: startDate,
            endDate: endDate
        }).then((result) => {
            return result.data;
        }).catch(() => {
            return [];
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static create(item: CreateActivityRequest) {
        return AppService.postAuthorized(this.API_URL, item);
    }

    static accomplished(id: number, dateTime: Date) {
        return AppService.postAuthorized(this.API_URL + "/" + id + "/accomplished", null, {dateTime: DateUtil.toJavaLocalDateTime(dateTime)});
    }

    static delete(id: number) {
        return AppService.deleteAuthorized(this.API_URL + "/" + id);
    }

    static deleteByIds = (ids: number[]) => {
        return AppService.deleteAuthorized(this.API_URL, ids);
    }

}

export {ActivityService};
