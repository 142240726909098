import React, {FormEvent, useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {changePageTitle, setAlert} from "../redux/actions";
import CustomTextField from "../custom-text-field/custom-text-field";
import CustomRow from "../custom-row/custom-row";
import {NumberUtil} from "../util/number-util";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomButton from "../custom-button/custom-button";
import {CashRegisterService} from "../cash-register/cash-register.service";

const CheckoutOpen = () => {
    const [valorEmDinheiro, setValorEmDinheiro] = useState<number>(0);
    const [valorInformado, setValorInformado] = useState<number>(0);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const getValorTotalEmCaixa = useCallback(async () => {
        CashRegisterService.getValorTotalEmCaixaParaAbrir().then((result) => {
            setValorEmDinheiro(result.data);
            setValorInformado(result.data);
        }).catch((error) => {
            dispatch(setAlert({show: true, message: error.data.message, severity: 'error'}));
            navigate('/home');
        })
    }, [dispatch, navigate]);

    useEffect(() => {
        dispatch(changePageTitle("Abrir caixa"));
        getValorTotalEmCaixa().then();
    }, [dispatch, getValorTotalEmCaixa]);

    const open = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        CashRegisterService.abreCaixa(valorInformado).then(() => {
            dispatch(setAlert({show: true, message: 'Caixa aberto com sucesso', severity: 'success'}));
            navigate('/home');
        }).catch((error) => {
            dispatch(setAlert({show: true, message: 'Erro ao abrir caixa - ' + error.data.message, severity: 'error'}));
        })
    }

    const cancel = () => {
        navigate('/home');
    }

    return (
        <form onSubmit={open}>
            <CustomRow>
                <CustomTextField fullWidth label="Valor total em caixa (R$)" type="number" value={valorEmDinheiro || ''}
                                 disabled/>
                <CustomTextField fullWidth required autoFocus label="Informe o valor em caixa (R$)" type="number"
                                 value={valorInformado || ''}
                                 onChange={(event) => setValorInformado(parseFloat(event.target.value))}/>
            </CustomRow>
            <Typography variant="subtitle1" gutterBottom>
                Diferença: {NumberUtil.currencyFormat(valorInformado - valorEmDinheiro)}
            </Typography>
            <CustomButtonGroup>
                <CustomButton type="submit" color="primary">Abrir</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )

}

export default React.memo(CheckoutOpen);