import React, {FormEvent, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import CustomButton from "../custom-button/custom-button";
import CustomButtonGroup from "../custom-button-group/custom-button-group";
import CustomRow from "../custom-row/custom-row";
import {changePageTitle, setAlert} from "../redux/actions";
import CustomTextField from "../custom-text-field/custom-text-field";
import {DateUtil} from "../util/2.0.0/date-util";
import {RelatorioService} from "../relatorio/relatorio.service";
import {FileUtil} from "../util/file-util";
import {decodeUint8Array} from "../util/string-util";

const CashJournal = () => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));
    const [endDate, setEndDate] = useState(DateUtil.toJavaSqlDate(DateUtil.currentDate()));

    useEffect(() => {
        dispatch(changePageTitle("Diário de caixa"));
    }, [dispatch]);

    const print = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();
        RelatorioService.getCashJournal(startDate, endDate).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        }, (result) => {
            if (result.status === 404) {
                dispatch(setAlert({show: true, message: 'Não há lançamentos para o período informado', severity: 'error'}));
                return;
            }
            const error = decodeUint8Array(result);
            dispatch(setAlert({show: true, message: error.message, severity: 'error'}));
        })
    }

    const changeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    }

    const changeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    }

    return (
        <form onSubmit={print}>
            <CustomRow>
                <CustomTextField fullWidth autoFocus required label="Data inicial" type="date"
                                 value={startDate} InputLabelProps={{shrink: true}}
                                 onChange={changeStartDate}/>
                <CustomTextField fullWidth required label="Data final" type="date"
                                 value={endDate} InputLabelProps={{shrink: true}}
                                 onChange={changeEndDate}/>
            </CustomRow>
            <CustomButtonGroup>
                <CustomButton color="primary" type="submit">Imprimir</CustomButton>
            </CustomButtonGroup>
        </form>
    );
}

export default React.memo(CashJournal);