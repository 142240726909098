import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {
    CentroDeCustoDetail,
    ForeignKey,
    PersonDetail
} from "../index";

import {DocumentService} from "./document.service";
import CustomTextField from "../typescript/custom-text-field/custom-text-field";
import CustomButton from "../typescript/custom-button/custom-button";
import CustomButtonGroup from "../typescript/custom-button-group/custom-button-group";
import CustomRow from "../typescript/custom-row/custom-row";
import {AuthService} from "../typescript/auth/auth.service";
import {changePageTitle, setAlert} from "../typescript/redux/actions";
import {PersonService} from "../person/person.service";
import {CentroDeCustoService} from "../centro-de-custo/centro-de-custo.service";
import {SaleService} from "../sale/sale.service";
import {PaymentTypeService} from "../payment-type/payment-type.service";
import Datatable from "../typescript/datatable/datatable";
import {Print} from "@mui/icons-material";
import {FileUtil} from "../typescript/util/file-util";
import {ColorIconEnum} from "../typescript/util/color-icon-enum";

export function DocumentDetail({id, onClose, dialog}) {

    const isAdminOrMaster = AuthService.userInRole("ADMIN") || AuthService.userInRole("MASTER");
    const [item, setItem] = useState(DocumentService.createDocument());
    const dispatch = useDispatch();
    const submitButton = useRef(null);

    const findOne = useCallback(async () => {
        const result = await DocumentService.findOne(id);
        setItem(result);
    }, [id]);

    useEffect(() => {
        if (!dialog) {
            dispatch(changePageTitle(id ? "Documento " + id : "Novo documento"));
        }
        if (id) {
            findOne().then();
        }
    }, [dispatch, findOne, id, dialog]);

    const save = (event) => {
        event.preventDefault();
        event.stopPropagation();
        submitButton.current.setAttribute("disabled", "disabled");
        DocumentService.save(item).then((result) => {
            submitButton.current.removeAttribute("disabled");
            onClose(result.data);
        }).catch((error) => {
            submitButton.current.removeAttribute("disabled");
            dispatch(setAlert({show: true, message: error.response.data.message, severity: 'error'}));
        })
    }

    const cancel = () => {
        onClose();
    }

    const setAPagar = (event) => {
        const newAPagar = event.target.checked;
        if (!newAPagar) {
            setItem({
                ...item,
                aPagar: event.target.checked,
                centrodecusto: CentroDeCustoService.createCentroDeCusto()
            })
        } else {
            setItem({
                ...item,
                aPagar: event.target.checked
            })
        }

    }

    const onChangePessoa = async (newPessoaId) => {
        setItem({
            ...item,
            pessoa: {
                id: newPessoaId
            },
            centrodecusto: CentroDeCustoService.createCentroDeCusto()
        })
        if (newPessoaId) {
            const pessoa = await PersonService.findOne(newPessoaId);
            if (pessoa) {
                let centrodecusto = CentroDeCustoService.createCentroDeCusto();
                if (item.aPagar && pessoa.centrodecusto) {
                    centrodecusto = pessoa.centrodecusto;
                }
                setItem({
                    ...item,
                    pessoa: pessoa,
                    centrodecusto: centrodecusto
                })
            }
        }
    }

    const onAddPessoa = async (pessoa) => {
        let centrodecusto = CentroDeCustoService.createCentroDeCusto();
        if (item.aPagar && pessoa.centrodecusto) {
            centrodecusto = await CentroDeCustoService.findOne(pessoa.centrodecusto.id);
        }
        if (pessoa) {
            setItem({
                ...item,
                pessoa: pessoa,
                centrodecusto: centrodecusto
            })
        }
    }

    const findPessoas = (page, size, sort, filter) => {
        return PersonService.findAll(page, size, sort, filter);
    }

    const setValor = (event) => {
        const newValor = event.target.value;
        const newValorFinal = DocumentService.calculaValorFinal(newValor, item.desconto, item.multa);
        setItem({
            ...item,
            valor: newValor,
            valorFinal: newValorFinal
        })
    }

    const setDesconto = (event) => {
        const newDesconto = event.target.value;
        const newValorFinal = DocumentService.calculaValorFinal(item.valor, newDesconto, item.multa);
        setItem({
            ...item,
            desconto: newDesconto,
            valorFinal: newValorFinal
        })
    }

    const setMulta = (event) => {
        const newMulta = event.target.value;
        const newValorFinal = DocumentService.calculaValorFinal(item.valor, item.desconto, newMulta);
        setItem({
            ...item,
            multa: newMulta,
            valorFinal: newValorFinal
        })
    }

    const setJurosDia = (event) => {
        setItem({
            ...item,
            jurosDia: event.target.value
        })
    }

    const setDataEmissao = (event) => {
        setItem({
            ...item,
            dataEmissao: event.target.value
        })
    }

    const setDataVencimento = (event) => {
        setItem({
            ...item,
            dataVencimento: event.target.value
        })
    }

    const setParcela = (event) => {
        setItem({
            ...item,
            parcela: event.target.value
        })
    }

    const setCardNumberOfParcels = (event) => {
        setItem({
            ...item,
            cardNumberOfParcels: event.target.value
        })
    }

    const setObservacao = (event) => {
        setItem({
            ...item,
            observacao: event.target.value
        })
    }

    const onChangeTipoDePagamento = async (newTipoDePagamentoId) => {
        setItem({
            ...item,
            tipopagamento: {
                id: newTipoDePagamentoId
            }
        })
        if (newTipoDePagamentoId) {
            const tipopagamento = await PaymentTypeService.findOne(newTipoDePagamentoId);
            if (tipopagamento) {
                setItem({
                    ...item,
                    tipopagamento: tipopagamento
                })
            }
        }
    }

    const onSelectTipoDePagamento = (tipopagamento) => {
        if (tipopagamento) {
            setItem({
                ...item,
                tipopagamento: tipopagamento
            })
        }
    }

    const findTipoDePagamentos = (page, size, sort, filter) => {
        return PaymentTypeService.findAll(page, size, sort, filter);
    }

    const onChangeCentroDeCusto = async (newCentroDeCustoId) => {
        setItem({
            ...item,
            centrodecusto: {
                id: newCentroDeCustoId
            }
        })
        if (newCentroDeCustoId) {
            const centrodecusto = await CentroDeCustoService.findOne(newCentroDeCustoId);
            if (centrodecusto) {
                setItem({
                    ...item,
                    centrodecusto: centrodecusto
                })
            }
        }
    }

    const onAddCentroDeCusto = (centrodecusto) => {
        if (centrodecusto) {
            setItem({
                ...item,
                centrodecusto: centrodecusto
            })
        }
    }

    const findCentroDeCustos = (page, size, sort, filter) => {
        return CentroDeCustoService.findAll(page, size, sort, filter);
    }

    const renderCentroDeCusto = () => {
        return item.aPagar && (
            <ForeignKey fullWidth required label="Tipo de gasto" value={item.centrodecusto} fieldKey={'id'}
                        fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeCentroDeCusto}
                        onSelect={onAddCentroDeCusto} onAdd={onAddCentroDeCusto} findAll={findCentroDeCustos}
                        dataColumnNames={['ID', 'Descrição']} dataColumns={['id', 'descricao']}
                        dataTypes={['number', 'text']} addComponent={<CentroDeCustoDetail/>}
                        disabled={!!item.dataPagamento}/>
        )
    }

    const recibo = async (item, index) => {
        DocumentService.reciboPagamentoParcial(item.id).then(function (result) {
            FileUtil.openPdfInNewTab(result);
        });
    }

    const rowOperations = [
        {color: () => ColorIconEnum.PRIMARY, onClick: recibo, label: () => 'Recibo', icon: () => <Print/>},
    ]

    const renderMovimentacoes = () => {
        return item.movimentacoes && item.movimentacoes.length > 0 && (
            <React.Fragment>
                <h3>Movimentações do documento</h3>
                <Datatable dataColumnNames={['Data', 'Valor', 'Descrição', 'Carteira', 'Usuário', 'Tipo', 'Bandeira', 'Quantidade de parcelas do cartão']}
                           dataColumns={['data', 'valor', 'descricao', 'carteira.id', 'createdBy', 'tipopagamento.descricao', 'bandeira.descricao', 'cardNumberOfParcels']}
                           dataTypes={['date', 'currency', 'text', 'number', 'text', 'text', 'text', 'number']}
                           data={item.movimentacoes} totalData={item.movimentacoes.length} rowOperations={rowOperations}/>
            </React.Fragment>
        )
    }

    const findSales = (page, size, sort, filter) => {
        return SaleService.findAllToSearch(page, size, sort, filter);
    }

    const onChangeSale = async (newSaleId) => {
        setItem({
            ...item,
            os: {
                id: newSaleId
            }
        })
        if (newSaleId) {
            const sale = await SaleService.findOne(newSaleId);
            if (sale) {
                setItem({
                    ...item,
                    os: sale
                })
            }
        }
    }

    const onSelectSale = (sale) => {
        if (sale) {
            setItem({
                ...item,
                os: sale
            })
        }
    }

    const renderSale = () => {
        return !item.aPagar && (
            <ForeignKey fullWidth label="Venda" value={item.os} fieldKey={'id'} disabled={!!item.dataPagamento}
                        fieldDescription={'pessoa.nome'} labelDescription='Pessoa' onChange={onChangeSale}
                        onSelect={onSelectSale} findAll={findSales}
                        dataColumnNames={['ID', 'Pessoa']} dataColumns={['id', 'pessoa.nome']}
                        dataTypes={['number', 'text']}/>
        )
    }

    const isCartao = () => {
        return PaymentTypeService.isCartao(item.tipopagamento);
    }

    return (
        <form onSubmit={save}>
            <Grid container spacing={1}>
                <FormControlLabel control={<Checkbox autoFocus checked={item.aPagar} onChange={setAPagar}/>}
                                  label="À pagar" disabled={!isAdminOrMaster || !!item.dataPagamento}/>
                <Grid item md xs={12}>
                    <ForeignKey fullWidth required label="Pessoa" value={item.pessoa} fieldKey={'id'}
                                disabled={!!item.dataPagamento}
                                fieldDescription={'nome'} labelDescription='Nome' onChange={onChangePessoa}
                                onSelect={onAddPessoa} onAdd={onAddPessoa} findAll={findPessoas}
                                dataColumnNames={['ID', 'Nome']} dataColumns={['id', 'nome']}
                                dataTypes={['number', 'text']} addComponent={<PersonDetail/>}/>
                </Grid>
            </Grid>
            <CustomRow>
                <CustomTextField type="number" fullWidth required label="Valor (R$)" value={item.valor}
                                 onChange={setValor}
                                 disabled={!!item.dataPagamento}/>
                <CustomTextField type="number" fullWidth label="Desconto (%)" value={item.desconto || ''}
                                 onChange={setDesconto} disabled={!!item.dataPagamento}/>
                <CustomTextField type="number" fullWidth label="Multa (%)" value={item.multa || ''} onChange={setMulta}
                                 disabled={!!item.dataPagamento}/>
                <CustomTextField type="number" fullWidth label="Juros por dia (%)" value={item.jurosDia || ''}
                                 onChange={setJurosDia} disabled={!!item.dataPagamento}/>
                <CustomTextField type="number" fullWidth label="Valor final (%)" value={item.valorFinal} disabled/>
            </CustomRow>
            <CustomRow>
                <CustomTextField fullWidth required label="Emissão" type="date" value={item.dataEmissao}
                                 InputLabelProps={{shrink: true}} onChange={setDataEmissao}
                                 disabled={!!item.dataPagamento}/>
                <CustomTextField fullWidth required label="Vencimento" type="date" value={item.dataVencimento || ''}
                                 InputLabelProps={{shrink: true}} onChange={setDataVencimento}
                                 disabled={!!item.dataPagamento}/>
                <CustomTextField type="number" fullWidth required={!item.aPagar} label="Parcela" value={item.parcela}
                                 onChange={setParcela}
                                 disabled={!!item.dataPagamento}/>
            </CustomRow>
            {renderSale()}
            <CustomTextField fullWidth label="Observação" value={item.observacao || ''} onChange={setObservacao}
                             disabled={!!item.dataPagamento}/>
            <CustomRow>
                <ForeignKey fullWidth required label="Tipo de pagamento" value={item.tipopagamento} fieldKey={'id'}
                            fieldDescription={'descricao'} labelDescription='Descrição' onChange={onChangeTipoDePagamento}
                            onSelect={onSelectTipoDePagamento} findAll={findTipoDePagamentos}
                            dataColumnNames={['ID', 'Descrição']} disabled={!!item.dataPagamento}
                            dataColumns={['id', 'descricao']} dataTypes={['number', 'text']}/>
                <CustomTextField type="number" fullWidth required={isCartao()} label="Número de parcelas para cartão"
                                 value={item.cardNumberOfParcels} onChange={setCardNumberOfParcels} disabled={!!item.dataPagamento}/>
            </CustomRow>
            {renderCentroDeCusto()}
            {renderMovimentacoes()}
            <CustomButtonGroup>
                <CustomButton ref={submitButton} color="primary" type="submit"
                              disabled={!!item.dataPagamento}>Salvar</CustomButton>
                <CustomButton type="button" color="neutral" onClick={cancel}>Cancelar</CustomButton>
            </CustomButtonGroup>
        </form>
    )
}
