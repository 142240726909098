import {AppService} from "../app/app.service";
import {PageResponse} from "../util/page-response";
import {CashRegister} from "../../cash-register/cash-register";
import {AxiosResponse} from "axios";

class CashRegisterService {

    static API_URL = "/api/registrocaixa/v2";

    static findAll = (page?: number, size?: number, sort?: string, filter?: string): Promise<PageResponse<CashRegister>> => {
        return AppService.getAuthorized(this.API_URL, {
            page: page,
            size: size,
            sort: sort,
            id: sort?.startsWith('id') ? filter : ''
        }).then((result: AxiosResponse<PageResponse<CashRegister>>) => {
            return result.data;
        }).catch(() => {
            return {
                content: [],
                totalElements: 0
            };
        })
    };

    static findOne(id: number) {
        return AppService.getAuthorized(this.API_URL + "/" + id).then((result) => {
            return result.data;
        }).catch(() => {
            return null;
        })
    }

    static async getValorTotalEmCaixaParaAbrir() {
        return AppService.getAuthorized(this.API_URL + "/valorTotalEmCaixaParaAbrir");
    }

    static async abreCaixa(dinheiro: number) {
        return AppService.postAuthorized(this.API_URL + "/abreCaixa", null, {
            dinheiro: dinheiro
        });
    }

    static getCaixaAberto() {
        return AppService.getAuthorized(this.API_URL + "/caixaAberto").then((result) => {
            return result.data;
        });
    }


    static fechaCaixa(dinheiro: number, sangria: number) {
        return AppService.postAuthorized(this.API_URL + "/fechaCaixa", null, {
            dinheiro: dinheiro,
            sangria: sangria
        });
    }
}

export {CashRegisterService};
